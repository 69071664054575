<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
// import researchMenu from "./research-menu";


/**
 * Shops Component
 */
export default {
  page: {
    title: "Research",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      // researchMenu,
      title: "Research",
      items: [
        {
          text: "99subs"
        },
        {
          text: "Research",
          active: true
        }
      ],
      playlistData: [
        {
          image: "https://i.scdn.co/image/ab67616d0000b273429db363543e77e67b65016b",
          title: "Nedick's",
          followers: 3243432,
          class: "Diamond",
          genre: [],
          url: "",
          platform: "spotify",
          featured: true
        },
        {
          image: "https://i.scdn.co/image/ab676d63000014757eaf8c1a55c79709e3ce0b3c",
          title: "Brendle's",
          followers: 4565456,
          class: "Diamond",
          genre: [],
          url: "",
          platform: "spotify",
          featured: true
        },
        {
          image: "https://i.scdn.co/image/ab67616d0000b273cc856960ed1de598bc20f7b5",
          title: "Tech Hifi",
          followers: 45354,
          class: "Diamond",
          genre: [],
          url: "",
          platform: "spotify",
          featured: false
        },
        {
          image: "https://i.scdn.co/image/ab67706c0000bebbad0bdc60279adbe0ec1ca990",
          title: "Lafayette",
          followers: 435,
          class: "Diamond",
          genre: [],
          url: "",
          platform: "spotify",
          featured: false
        },
        {
          image: "https://i.scdn.co/image/ab67616d0000b273206a04fbf62b2b0225b3d790",
          title: "Packer",
          followers: 4355543,
          class: "Diamond",
          genre: [],
          url: "",
          platform: "spotify",
          featured: false
        },
        {
          image: "https://i.scdn.co/image/ab67616d0000b273f2fa52fe95f4bbbca3eef802",
          title: "Micro Design",
          followers: 66323,
          class: "Diamond",
          genre: [],
          url: "",
          platform: "spotify",
          featured: false
        },
        {
          image: "https://i.scdn.co/image/ab67706c0000bebb03c24aa70f9f8199664f956d",
          title: "Keeney's",
          followers: 123232,
          class: "Diamond",
          genre: [],
          url: "",
          platform: "spotify",
          featured: false
        },
        {
          image: "https://i.scdn.co/image/ab67706c0000bebbcf5fde8e1c5c847df693d6da",
          title: "Tech Hifi",
          followers: 343,
          class: "Diamond",
          genre: [],
          url: "",
          platform: "spotify",
          featured: false
        }
      ]
    };
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-xl-3 col-sm-6" v-for="(data, index) in playlistData" :key="index">
        <div class="text-center">
          <img :src="`${data.image}`" alt class="avatar-xl mt-2 mb-2 img-thumbnail-dark" />
          <div class="media-body">
            <h6 class="text-truncate">
              <a href="#" class="text-dark">{{data.title}}</a>
            </h6>
            <p class="text-muted">
              <i class="mdi mdi-spotify mr-1"></i>
              <i class="mdi mdi-account-supervisor-circle mr-1"></i> {{ abbreviateNumber(data.followers)}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-xl-12">
        <div class="text-center my-3">
          <a href="javascript:void(0);" class="text-primary">
            <i class="mdi mdi-loading mdi-spin font-size-20 align-middle mr-2"></i> Load more
          </a>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
.search-box .search-icon {
    font-size: 22px;
    top: 0px;
    line-height: 50px;
}
.avatar-xl {
  height: 15rem;
  width: 15rem;
}
</style>
